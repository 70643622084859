import React, { Component } from 'react';
import { iframe } from '../../../common/index';
import webApp from '../../../webapp/index';
import { _getSession } from 'api/rpc/rpcBase';
import { Spin, Row } from 'antd';
import { bindLifecycle } from 'utils/bindLifecycle';
import './index.less';

class Figure extends Component {
    constructor (props) {
        super(props);
        this.state = {
            options: {
                max: 100,
                min: 0,
                height: 102,
                width: props.width || 242,
                lowColor: 'green', //取值低于阈值的颜色
                highColor: 'red', //取值高于阈值的颜色
                threshold: 50,
                equal: props.equal || 0 // 0=> 大于等于阀值变红色 1=> 大于阀值阀值变红
            },
            canvasLeft: props.width - 1, // 相对位置，自减
            bottom: 50, // threshold位置
            dataTemp: [], // 渲染数组
            iframeSrc: '', // iframe地址
            isLoading: false, // 渲染flag
            figureData: -1, // CGI初始数据
            figureIndex: -1, // CGI初始数据递增flag位
            figureDataTemp: [] // CGI数据临时存储
        };
        this.timer = null;
        this.tempData = []; // CGI数据临时存储
    }
    componentDidMount () {
        this.handleThresholdChange(this.props.threshold); // 初始渲染thr
        this.handleCgiDataGet(this.props.subscribeInfo); // 初始渲染CgiData
        this.setState({
            isLoading: true
        });
    }
    componentWillUnmount () {

    }
    componentDidActivate () {

    }
    componentWillUnactivate () {

    }
    componentWillReceiveProps (nextProps) {
        if (this.props.threshold !== nextProps.threshold) { // 阈值改变
            this.handleThresholdChange(nextProps.threshold);
        }
    }
    // 音频检测父组件activate触发，视频动检Modal组件会强制re-render，不使用该函数
    start = () => {
        this.setState({
            dataTemp: [] // 清空数据，重新推入
        }, () => {
            this.handleThresholdChange(this.props.threshold);
            this.handleCgiDataGet(this.props.subscribeInfo);
        });
    }
    // 清除定时器
    handleInterValClear = () => {
        if (this.timer !== null) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }
    // iframeSrc数据return
    handleIframeSrc = () => {
        return this.state.iframeSrc;
    }
    // 更新阈值
    handleThresholdChange = (thr) => {
        const { options } = this.state;
        if (typeof thr === 'number') {
            if (thr > options.max) thr = options.max;
            else if (thr < options.min) thr = options.min;
            options.threshold = thr;
            var height_rate = (options.threshold - options.min) / (options.max - options.min);
            this.setState({
                options,
                bottom: height_rate * (options.height - 1)
            });
        } else {
            this.setState({
                bottom: options.threshold
            });
        }
    }
    // CGI数据获取
    handleCgiDataGet = (target) => {
        if (webApp.NotifyVersion) {
            iframe.devNotify.initComet(_getSession());
            this.props.type === 'audio' ?
                iframe.devNotify.subscribe(target, data => { // 音频数据处理函数
                    let channel = this.props.currentChannel,
                        audioData = data.data;
                    if ((channel === audioData.Channel) && (typeof audioData.AudioMutation === 'number')) {
                        // let figureDataTemp = this.state.figureDataTemp;
                        // figureDataTemp.push(audioData.AudioMutation);
                        // this.setState({
                        //     figureDataTemp
                        // });
                        this.tempData.push(audioData.AudioMutation);
                    }
                }) :
                iframe.devNotify.subscribe(target, data => { // 视频数据处理函数
                    data.data.map((item) => {
                        if (item.id === this.props.curWindowId) {
                            // let figureDataTemp = this.state.figureDataTemp;
                            // figureDataTemp.push(item.threshold);
                            // this.setState({
                            //     figureDataTemp
                            // });
                            this.tempData.push(item.threshold);
                        }
                    });
                });
            // development环境socket
            if (process.env.NODE_ENV === 'development') {
                let ws = new WebSocket('ws://10.34.9.161:8181');
                ws.onmessage = (event) => {
                    let dataa = JSON.parse(event.data);
                    if (dataa.callback) {
                        let data = dataa.params;
                        if (this.props.type === 'audio') {
                            let channel = this.props.currentChannel,
                                audioData = data.data;
                            if ((channel === audioData.Channel) && (typeof audioData.AudioMutation === 'number')) {
                                // let figureDataTemp = this.state.figureDataTemp;
                                // figureDataTemp.push(audioData.AudioMutation);
                                // this.setState({
                                //     figureDataTemp
                                // });
                                this.tempData.push(audioData.AudioMutation);
                            }
                        } else {
                            data.data.map((item) => {
                                // console.log(this.props.curWindowId + 'start');
                                if (item.id === this.props.curWindowId) {
                                    // console.log(item.threshold);
                                    // let figureDataTemp = this.state.figureDataTemp;
                                    // figureDataTemp.push(item.threshold);
                                    // this.setState({
                                    //     figureDataTemp
                                    // });
                                    this.tempData.push(item.threshold);
                                }
                            });
                        }
                    }
                };
            }
            // 170ms推送一次数据
            this.timer = setInterval(() => {
                // console.log(this.state.figureDataTemp);
                this.handleCgiDataDeal(this.tempData.pop() || 0);
                this.tempData = [];
                // this.handleCgiDataDeal(this.state.figureDataTemp.pop() || 0);
                // this.setState({ figureDataTemp: [] }); // 每一次推送后都把数据清空
            }, 170);
        } else {
            let iframeSrc = this.props.type === 'audio'
                ? 'cgi-bin/audiomutation.cgi?sessionId=' + _getSession()
                : '/cgi-bin/videomotion.cgi?sessionId=' + _getSession();
            this.setState({ iframeSrc });
        }
    }
    // CGI数据处理
    handleCgiDataDeal (data) {
        // console.log(data + '-------');
        this.setState((state) => {
            ++state.figureIndex; // 数据的indexFlag位
        });
        this.setState({
            figureData: data
        }, () => {
            this.handleCgiDataDraw(this.state.figureData, this.state.figureIndex);
        });
    }
    // CGI数据渲染
    handleCgiDataDraw (dataa, flagg) {
        let dataTemp = this.state.dataTemp;
        const { options } = this.state;
        let thr = dataa;
        let flag = flagg;
        if (thr > options.max) thr = options.max;
        else if (thr < options.min) thr = options.min;
        this.setState((state) => {
            state.canvasLeft--;
        });
        if (dataTemp.length > options.width - 3) { // dataTemp的最大长度
            dataTemp.shift();
            dataTemp.push({ 'thr': thr, 'flag': flag, 'mark': options.threshold });
        } else {
            dataTemp.push({ 'thr': thr, 'flag': flag, 'mark': options.threshold });
        }
        this.setState({ dataTemp });
    }
    render () {
        const { options } = this.state;
        const items = this.state.dataTemp.map((item, index) => {
            return (
                <div key={index} style={{
                    height: (item.thr - options.min) / (options.max - options.min) * options.height,
                    width: '1px',
                    background: item.thr >= (item.mark + options.equal) ? options.highColor : options.lowColor,
                    left: item.flag,
                    position: 'absolute',
                    bottom: '0px'
                }}>
                </div>
            );
        });
        if (this.state.isLoading) {
            return (
                <Row className="_figure_">
                    <Row className="figure-wrap" style={{ width: options.width, height: options.height }}>
                        <Row className="figure-canvas" style={{ left: this.state.canvasLeft }}>
                            {items}
                        </Row>
                        <Row className="figure-threshold" style={{ bottom: this.state.bottom }}>
                        </Row>
                    </Row>
                </Row>
            );
        } else {
            return (
                <div className='empty'>
                    <Spin size="large" />
                </div>
            );
        }
    }
}

export default bindLifecycle(Figure);
